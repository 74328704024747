
import { Vue, Component, Prop, Watch } from "vue-property-decorator"
import {
  UserRolesQuery,
  UserAdvanced,
  AddUserMutation,
  UpdateUserMutation,
  UsersQuery,
} from "@/gql"
import { RefetchQueryDescription } from "apollo-client/core/watchQueryOptions"

@Component
export default class AddUserForm extends Vue {
  @Prop() readonly user?: UserAdvanced
  @Prop() readonly queries?: RefetchQueryDescription

  readonly UserRolesQuery = UserRolesQuery
  loading = false
  refetchQueries: RefetchQueryDescription = [
    {
      query: UsersQuery,
    },
  ]

  defaultForm: {
    id: string
    active: boolean
    forceUpdate: boolean
    firstName: string
    lastName: string
    email: string
    roleIds: number[]
  } = {
    id: "",
    active: true,
    forceUpdate: false,
    firstName: "",
    lastName: "",
    email: "",
    roleIds: [],
  }

  addUserForm = { ...this.defaultForm }
  message: string | null = null

  @Watch("user", { immediate: true })
  onUserChange() {
    if (this.user) {
      this.addUserForm.id = this.user.id
      this.addUserForm.firstName = this.user.firstName
      this.addUserForm.lastName = this.user.lastName
      this.addUserForm.email = this.user.email
      this.addUserForm.active = this.user.active
      this.addUserForm.roleIds = this.user.roles.map((x) => x.id)
    } else {
      this.addUserForm = { ...this.defaultForm }
    }
  }

  async updateUser() {
    try {
      // mutate
      this.loading = true
      this.addUserForm.forceUpdate = true

      const mutation = UpdateUserMutation,
        result = await this.$apollo.mutate({
          mutation: mutation,
          variables: this.addUserForm,
          refetchQueries: this.queries,
        })

      if (result && result.data) {
        let data = result.data.updateUser,
          successMsg = "User updated successfully"

        if (data.error) this.addMutationError(data.error)
        else {
          this.addSuccess(successMsg)
          this.$emit("close")

          this.addUserForm = {
            ...this.defaultForm,
          }

          this.$refs.observer && (this.$refs.observer as any).reset()
        }
      }
    } catch (e) {
      this.addGraphQLError(e as Error)
    } finally {
      this.loading = false
    }
  }

  async addUser() {
    try {
      // mutate
      this.loading = true

      const mutation = this.user ? UpdateUserMutation : AddUserMutation,
        result = await this.$apollo.mutate({
          mutation: mutation,
          variables: this.addUserForm,
          refetchQueries: this.queries,
        })

      if (result && result.data) {
        let data = this.user ? result.data.updateUser : result.data.addUser,
          successMsg = this.user ? "User updated successfully" : "User created successfully"

        if (data.error) {
          if (data.error.code) {
            if (data.error.code == "102") {
              this.message =
                "This user does not have a consultant account. Would you like to proceed and grant the permissions to the user? "
            } else if (data.error.code == "103") {
              this.message =
                "This user's job title does not match the role assigned. Would you like to proceed to grant the permissions to the user?"
            }
          } else {
            this.addMutationError(data.error)
          }
        } else {
          this.addSuccess(successMsg)
          this.$emit("close")

          this.addUserForm = {
            ...this.defaultForm,
          }

          this.$refs.observer && (this.$refs.observer as any).reset()
        }
      }
    } catch (e) {
      this.addGraphQLError(e as Error)
    } finally {
      this.loading = false
    }
  }
}
