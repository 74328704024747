var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('AppPage',{attrs:{"title":"Users"},scopedSlots:_vm._u([{key:"tools",fn:function(){return [(_vm.can('add_user', 'admin'))?_c('SecondaryIconButton',{attrs:{"vIcon":"la-plus"},on:{"click":_vm.onAddNewUser}}):_vm._e()]},proxy:true}])},[_c('Table',{attrs:{"query":_vm.UsersQuery,"queryKey":"users","searchFilters":[
      {
        field: 'search',
        label: 'Search',
      },
    ],"headers":_vm.headers,"itemActions":_vm.itemActions},on:{"click:row":_vm.gotoDetailPage},scopedSlots:_vm._u([{key:`item.active`,fn:function({ item }){return [_c('v-chip',{attrs:{"color":item.active
            ? 'green lighten-5 green--text'
            : 'amber lighten-5 amber--text text--darken-2',"small":""}},[_vm._v(_vm._s(item.active ? "Enabled" : "Disabled"))])]}},{key:`item.roles`,fn:function({ item }){return [(item.roles.length)?_c('span',[_vm._v(_vm._s(item.roles.map((role) => role.name).join(", ")))]):_c('span',[_vm._v("-")])]}}],null,true)}),_c('form-dialog',{attrs:{"label":_vm.activeUser ? 'Edit user' : 'Add new user'},model:{value:(_vm.showAddUserDialog),callback:function ($$v) {_vm.showAddUserDialog=$$v},expression:"showAddUserDialog"}},[_c('v-card',{staticClass:"pa-8"},[_c('add-user-form',{attrs:{"user":_vm.activeUser,"queries":['usersQuery']},on:{"close":() => {
            _vm.showAddUserDialog = false
            _vm.activeUser = null
          }}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }