
import { Vue, Component } from "vue-property-decorator"
import AppPage from "@/components/layout/AppPage.vue"
import { UsersQuery, UserAdvanced } from "@/gql"
import AddUserForm from "@/components/forms/AddUserForm.vue"
import { ActionType } from "@/components/widgets/common/FilterBar.vue"
import { RefetchQueryDescription } from "apollo-client/core/watchQueryOptions"

@Component({ components: { AppPage, AddUserForm } })
export default class Users extends Vue {
  readonly UsersQuery = UsersQuery
  itemActions!: ActionType
  showAddUserDialog = false
  activeUser: UserAdvanced | null = null

  refetchQueries: RefetchQueryDescription = [
    {
      query: UsersQuery,
    },
  ]

  readonly headers = [
    { text: "First name", value: "firstName", sortable: true },
    { text: "Last name", value: "lastName", sortable: true },
    { text: "Role", value: "roles", custom: true, sortable: false },
    { text: "Email address", value: "email", sortable: true },
    { text: "Status", value: "active", custom: true, sortable: true },
  ]

  gotoDetailPage(user: UserAdvanced) {
    this.$router.push({
      name: this.$routes.UserDetail,
      params: { id: user.id.toString() },
    })
  }

  onAddNewUser() {
    this.activeUser = null
    this.showAddUserDialog = true
  }

  created() {
    this.itemActions = {
      View: (user: UserAdvanced) => {
        this.gotoDetailPage(user)
      },
      Edit: (user: UserAdvanced) => {
        this.activeUser = user
        this.showAddUserDialog = true
      },
    }
  }
}
